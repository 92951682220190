import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`4 Rounds for time of:`}</p>
    <p>{`1/2 lap run to turf`}</p>
    <p>{`280ft sled push (down & back x 2, empty sled)`}</p>
    <p>{`1/2 lap run to front of building`}</p>
    <p>{`20 step ups (24/20, alternating 10 per leg)`}</p>
    <p>{`15 T2B`}</p>
    <p>{`then, lower body mobility & stretching.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      